import * as React from 'react';
import {Routes, Route} from "react-router-dom";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './App.css';
import './index.css';
import Layout from "./Screens/Layout";
import Cookies from "js-cookie";
import {useEffect} from "react";

const MesContactsSites = React.lazy(() => import("./Screens/MesContactsSites"));
const MesContactsObjetDemande = React.lazy(() => import("./Screens/MesContactsObjetDemande"));
const MesContactsPersons = React.lazy(() => import("./Screens/MesContactsPersons"));
const MesContactsContactPerson = React.lazy(() => import("./Screens/MesContactsContactPerson"));

const App = () => {
    //theme
    const [mode, setMode] = React.useState(Cookies.get('theme'));

    useEffect(() => {
        if (Cookies.get('theme')) {
            setMode(Cookies.get('theme'));
        } else {
            setMode('dark'); // thème par défaut
        }
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (Cookies.get('theme') === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, []);

    const theme = React.useMemo(
        () =>
            createTheme({ //Material  spécification
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                color: 'inherit', // Hérite de la couleur du parent
                                backgroundColor: 'transparent', // Fond transparent
                                boxShadow: 'none', // Pas d'ombre
                                '&:hover': {
                                    backgroundColor: 'transparent', // Fond transparent au survol
                                    boxShadow: 'none', // Pas d'ombre au survol
                                },
                                '&:active': {
                                    boxShadow: 'none', // Pas d'ombre en état actif
                                },
                                '&:focus': {
                                    boxShadow: 'none', // Pas d'ombre en état focus
                                },
                            },
                        },
                    },
                },
                palette: {
                    mode,
                },
                accordion: {
                    backgroundImage: 'none',
                },
                accordionSummary: {
                    backgroundImage: 'none',
                },
                typography: {
                    fontFamily: 'var(--font-family-app)', // Définit la font par défaut pour tous les composants MATERIAL UI
                },
            }),
        [mode],
    );

    React.useEffect(() => {
        Cookies.set('theme', theme.palette.mode, {domain: '.cristal-hub.fr'});
        // 👇 add class to body element
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (theme.palette.mode === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, [theme]);

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Routes>
                    <Route path="/">
                        <Route index
                               element={
                                   <React.Suspense fallback={<>...</>}>
                                       <MesContactsSites/>
                                   </React.Suspense>
                               }
                        />
                        <Route
                            path="objetDemande/:idSite"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <MesContactsObjetDemande/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="persons"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <MesContactsPersons/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="persons/contact/:idPerson"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <MesContactsContactPerson/>
                                </React.Suspense>}/>
                    </Route>
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}

export default App;
