import BlueImageButtonTablette from '../../images/hexagone-blue-tab56x61.svg';
import BlueImageButtonTabletteDark from '../../images/hexagone-blue-tab56x61-dark.svg';
import PinkImageButtonTablette from '../../images/hexagone-pink-tab56x61.svg';
import WhiteImageButtonTablette from '../../images/hexagone-white-tab56x61.svg';
import BlueConfigImageButtonTabletteHover from '../../images/hexagone-blue-config-tab-hover.svg';
import BlueConfigImageButtonTablette from '../../images/hexagone-blue-config-tab.svg';

import {makeStyles, withStyles} from "@mui/styles";
import ButtonBase from "@mui/material/ButtonBase";
import {styled} from "@mui/material/styles";
import {Badge} from "@mui/material";
import Cookies from "js-cookie";


/**
 * Logo, settings, home, back header button styles
 */
export const useStyles = makeStyles({
    customBadge: {
        backgroundColor: props => props.color,
    }
});
export const StyledBadge = withStyles(() => ({
    badge: {
        backgroundColor: "#FFC107", // COLORATION DU BADGE
        border: Cookies.get('theme') === 'light' ? '2px solid #FFFFFF' : '2px solid #00233E',
    },
}))(Badge);

export const CristalBlueButton = styled(ButtonBase)(() => ({
    position: 'relative',
    height: '57px',
    width: '52px',
    backgroundImage: `url(${BlueConfigImageButtonTablette})`,
    backgroundSize: '52px 57px',
    marginLeft: '8px',
    '&:hover': {
        backgroundImage: `url(${BlueConfigImageButtonTabletteHover})`,
    },
}));

export const CristalBackBleuButton = styled(ButtonBase)(({theme}) => ({
    position: 'relative',
    height: '57px',
    width: '52px',
    backgroundImage: theme.palette.mode === 'light' ? `url(${BlueImageButtonTablette})` : `url(${BlueImageButtonTabletteDark})`,
    backgroundSize: '52px 57px',
    marginLeft: '6px'
}));

export const CristalPinkButton = styled(ButtonBase)(() => ({
    position: 'relative',
    height: '57px',
    width: '52px',
    backgroundImage: `url(${PinkImageButtonTablette})`,
    backgroundSize: '52px 57px'
}));

export const CristalWhiteButton = styled(ButtonBase)(() => ({
    position: 'relative',
    height: '57px',
    width: '52px',
    backgroundImage: `url(${WhiteImageButtonTablette})`,
    filter: 'drop-shadow(0px 8px 18px rgba(0, 194, 239, 0.4))',
    backgroundSize: '52px 57px'
}));

export const CristalLogo = styled('img')(() => ({
    marginLeft: '0'
}));

/**
 * End header
 */
