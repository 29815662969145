// src/components/NotificationMenu.js

import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Menu} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from "@mui/material/Grid2";
import {CristalPinkButton} from "./ComponentLayout";
import {useTheme} from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';


const NotificationMenu = ({
                              anchorEl,
                              bShowNotifications,
                              fCloseNotifications,
                              fRemoveNotifications,
                              calendars,
                              documents
                          }) => {
    const theme = useTheme();


    return (
        <Menu
            anchorEl={anchorEl}
            open={bShowNotifications}
            onClose={fCloseNotifications}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{marginTop: '80px'}}
            PaperProps={{
                sx: {
                    padding: '0 14px 6px 14px',
                    width: {xs: '95vw', sm: '45vw'},
                    borderRadius: '10px',
                    backgroundImage: 'none',
                    backgroundColor: theme.palette.mode === 'light' ? '#B3EDFA' : '#004072',
                },
            }}
        >
            <Grid container direction="row" justifyContent="space-between" alignItems="center"
                  sx={{marginBottom: '8px'}}>
                <Typography sx={{fontFamily: 'PoppinsSemiBold'}}>
                    Notifications
                </Typography>
                <CristalPinkButton
                    sx={{backgroundSize: '32px 37px', height: '37px', width: '32px', marginRight: '8px'}}
                    disableRipple
                    onClick={fCloseNotifications}
                >
                    <img src={require('../../images/icon-close-x.svg').default} alt="CristalHub"
                         className="CristalNotifButton"/>
                </CristalPinkButton>
            </Grid>

            <Grid container direction="column">
                {calendars.length > 0 && (
                    <Accordion
                        sx={{
                            backgroundImage: 'none',
                            margin: '14px 0!important',
                            borderRadius: '10px!important',
                            backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F'
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            expandIcon={<ExpandMoreIcon/>}
                            sx={{
                                borderRadius: '10px',
                                backgroundImage: 'none',
                                backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F',
                            }}
                        >
                            <Typography>
                                Rendez-vous ({calendars.length})
                            </Typography>
                        </AccordionSummary>
                        {Array.isArray(calendars) && calendars.map((calendar, index) => (
                            <AccordionDetails
                                sx={{
                                    borderTop: theme.palette.mode === 'light' ? "1px solid #00233E" : "1px solid #FFF"
                                }}
                                key={index}
                            >
                                {calendar.eventstatus === 'Annulé' && (
                                    <Grid container direction="row" alignItems="center">
                                        <InfoRoundedIcon/>
                                        <ListItemText sx={{paddingLeft: "8px"}} primary={`${calendar.eventstatus}`}/>
                                    </Grid>
                                )}
                                <Grid container direction="column" alignItems="left" justifyContent="space-between"
                                    // Si event annulé → barré
                                      sx={{textDecoration: calendar.eventstatus === 'Annulé' ? 'line-through' : 'none'}}
                                >
                                    <ListItemText
                                        primary={`${calendar.dateEvenement}  ${calendar.complement ? '-' + calendar.complement : ''}`}/>
                                    <Grid container direction="row">
                                        <ListItemText secondary={`${calendar.label}`}/>
                                        <Grid item sx={{cursor: "pointer"}}
                                              onClick={() => fRemoveNotifications(calendar.externalId)}>
                                            <img
                                                src={theme.palette.mode === 'light' ? require('../../images/icon-trash-01.svg').default : require('../../images/icon-trash-01-dark.svg').default}
                                                alt="suppression rendez-vous notification icone"
                                                className="CristalNotifButton"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        ))}
                    </Accordion>
                )}
                {documents.length > 0 && (
                    <Accordion
                        sx={{
                            margin: '14px 0!important',
                            backgroundImage: 'none',
                            borderRadius: '10px!important',
                            backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F'
                        }}
                    >
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                            expandIcon={<ExpandMoreIcon/>}
                            sx={{
                                borderRadius: '10px',
                                backgroundImage: 'none',
                                backgroundColor: theme.palette.mode === 'light' ? '#CCF3FC' : '#00508F',
                            }}
                        >
                            <Typography>
                                Documents non lus ({documents.length})
                            </Typography>
                        </AccordionSummary>
                        <Grid item
                              sx={{borderTop: theme.palette.mode === 'light' ? "1px solid #00233E" : "1px solid #FFF"}}>
                            {Array.isArray(documents) && Object.entries(
                                documents
                                    .sort((a, b) => {
                                        const parseDate = (dateStr) => {
                                            const [day, month, year] = dateStr.split(' ')[0].split('/');
                                            return new Date(`20${year}-${month}-${day}`);
                                        };

                                        const dateA = parseDate(a.dateEvenement);
                                        const dateB = parseDate(b.dateEvenement);

                                        return dateB - dateA;
                                    })
                                    .reduce((groups, document) => {
                                        const groupName = document.alveole;
                                        if (!groups[groupName] && groups[groupName] !== 'Mes rendez-vous') {
                                            groups[groupName] = [];
                                        }
                                        groups[groupName].push(document);
                                        return groups;
                                    }, {})).map(([groupName, group], index) => (
                                <AccordionDetails key={index}>
                                    <Accordion
                                        sx={{
                                            borderRadius: '10px!important',
                                            backgroundImage: 'none',
                                            backgroundColor: theme.palette.mode === 'light' ? '#e4f7fc' : '#3372a4'
                                        }}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            expandIcon={<ExpandMoreIcon/>}
                                            sx={{
                                                borderRadius: '10px!important',
                                                backgroundImage: 'none',
                                                backgroundColor: theme.palette.mode === 'light' ? '#e4f7fc' : '#3372a4',
                                            }}
                                        >
                                            <Typography>{groupName} ({group.length})</Typography>
                                        </AccordionSummary>
                                        {group.map((document, index) => (
                                            <AccordionDetails key={index}>
                                                <Grid container direction="column" alignItems="left"
                                                      justifyContent="space-between">
                                                    <ListItemText onClick={fCloseNotifications}
                                                                  primary={`${document.label} ${document.complement ?? ""}`}/>
                                                    <Grid item>
                                                        <Grid container direction="row">
                                                            <ListItemText
                                                                secondary={document.dateEvenement.split(' ')[0]}/>
                                                            <Grid item sx={{cursor: "pointer"}}
                                                                  onClick={() => fRemoveNotifications(document.externalId)}>
                                                                <img
                                                                    src={theme.palette.mode === 'light' ? require('../../images/icon-trash-01.svg').default : require('../../images/icon-trash-01-dark.svg').default}
                                                                    alt="suppression rendez-vous notification icone"
                                                                    className="CristalNotifButton"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        ))}
                                    </Accordion>
                                </AccordionDetails>
                            ))}
                        </Grid>
                    </Accordion>
                )}
            </Grid>
        </Menu>
    );
};

export default NotificationMenu;
